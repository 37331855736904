import * as React from 'react'
import Layout from '../../../layouts'
import LargeHero from '../../../components/elements/largeHero'
import { Body, SizedText, Canon } from '../../../components/elements/typography'
import Grid from '../../../components/elements/grid'
import PoliciesMenu from "../../../components/molocules/menus/policiesMenu"
import SEO from '../../../components/elements/seo'

const AccessibilityPage = ({}) => {

  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>

      <SEO
        title="Accessibility Policy | TCB Trust Center"
        description="These terms govern the use of this website. Please read them carefully before accessing the site. If you do not agree with these terms, do not access the website. By accessing the website 
        or any of its pages, you agree to be bound by these terms of use."
        location="/about/policies/accessibility"
        ogImg='/images/social-graph/the-claxton-bank.jpg'
      /> 

      <LargeHero.Container
        padding="3rem 0 4rem 0"
        background={{
          //backgroundColor: `#b2a08a`,
          backgroundImage: `url(/images/background-deltas.jpg)`, 
          backgroundRepeat: `no-repeat`, 
          backgroundAttachment: `scroll`, 
          backgroundPosition: `top left`, 
          backgroundSize: `cover`,
        }}
        breadcrumbLinks={[
          { path: "/", text: "Home" },
          { path: "/about/", text: "About Us" },
          { path: "/about/policies/", text: "Policies" },
          { path: "/about/policies/accessibility", text: "Accessibility " }
        ]}
        styles={{
          paddingBottom: `5rem`,
          overflow: `hidden`,
          '.breadcrumbs, .breadcrumbs [aria-current="page"]': {color: `#fff`},
          '.heroContent': { paddingBottom: `3rem`},
          '@media (min-width: 680px)': {
            '.heroContent': { width: `100% !important`, marginBottom: `0`},
            '.heroImage': { width: `0 !important`,},
          }
        }}
      >
        <LargeHero.Content styles={{paddingTop: `1rem`, color: `#fff`}}>
          <LargeHero.Eyebrow text="Policies & Disclosures" styles={{borderColor: `#fff !important`}}/>
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="4.624">Website Accessibility Policy</SizedText>
          </h1>
        </LargeHero.Content>
      </LargeHero.Container>

      <Grid.FullWidthSection>
        <Grid.Block start={[2,2,2,2,2,2,3]} span={[12,12,12,12,8,8,7]} css={{
          paddingBottom: `4rem`,
          'p': {textAlign: `justify`, color: "#707070"},
          'h1': {color: `#1d449e`, margin: `0`, padding: `4rem 0 2rem 0`},
          'h2': { marginTop: `2rem`, color: `#3498db`, fontFamily: `proxima-nova`},
          'h3': { padding: `2rem 0 1rem 0`},
          'h4': { paddingTop: `1rem`}
        }}>

           <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{paddingTop: `1rem`}}>
            Commitment to Accessibility
          </SizedText>
          <SizedText as="p" smallSize="1" largeSize="1.125" css={{padding: `2rem 0 0 0`, lineHeight: '1.5 !important'}}>
            The Claxton Bank is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone and 
            applying the relevant accessibility standards to ensure we provide equal access to all of our users.
          </SizedText>

          <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{paddingTop: `1rem`}}>
            Standards
          </SizedText>
          <SizedText as="p" smallSize="1" largeSize="1.125" css={{padding: `2rem 0 0 0`, lineHeight: '1.5 !important'}}>
            The Claxton Bank aims to conform to the guidelines of the Web Content Accessibility Guidelines (WCAG) 2.1 Level AA, a set of rules, guidelines, and best practices used to 
            ensure digital content is accessible to everyone, including individuals with disabilities.
          </SizedText>

          <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{paddingTop: `1rem`}}>
            Ongoing Effort
          </SizedText>
          <SizedText as="p" smallSize="1" largeSize="1.125" css={{padding: `2rem 0 0 0`, lineHeight: '1.5 !important'}}>
            Accessibility is an ongoing effort at TCB. We are continuously working to improve the accessibility of our website and services to ensure we meet or exceed the requirements 
            of Section 508 of the Rehabilitation Act of 1973 and the Americans with Disabilities Act (ADA).
          </SizedText>

          <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{paddingTop: `1rem`}}>
            Accessibility Features
          </SizedText>
          <SizedText as="p" smallSize="1" largeSize="1.125" css={{padding: `2rem 0 0 0`, lineHeight: '1.5 !important'}}>
           Our website includes the following features designed to improve accessibility for users with disabilities:

           <ul>
            <li>Text alternatives for non-text content</li>
            <li>Content that can be presented in different ways without losing information or structure</li>
            <li>Functionality that can be operated through a variety of input methods</li>
            <li>Content that is easily navigable and operable</li>
           </ul>
          </SizedText>

          <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{paddingTop: `1rem`}}>
            Feedback
          </SizedText>
          <SizedText as="p" smallSize="1" largeSize="1.125" css={{padding: `2rem 0 0 0`, lineHeight: '1.5 !important'}}>
            We welcome your feedback on the accessibility of our website. Please let us know if you encounter accessibility barriers:
           <ul>
            <li><b>Phone:</b> 912-739-3322</li>
            <li><b>E-Mail:</b> info@tcbga.bank</li>
            <li><b>Address:</b> 121 West Main Street, Claxton GA 30417</li>
           </ul>

            We try to respond to feedback within 1 business day.
          </SizedText>

          <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{paddingTop: `1rem`}}>
            Monitoring and Training
          </SizedText>
          <SizedText as="p" smallSize="1" largeSize="1.125" css={{padding: `2rem 0 0 0`, lineHeight: '1.5 !important'}}>
            TCB is committed to training staff on accessibility issues and continually monitoring the state of accessibility on our website to make improvements where necessary.
          </SizedText>











        </Grid.Block>
        <Grid.Block start={[2,2,2,2,10,10]} span={[12,12,12,12,4,3]}
          css={{ 
            margin: `4rem 0`,
        }}>
          
          <PoliciesMenu />
        </Grid.Block>
      </Grid.FullWidthSection>
    </Layout> 
  )
}

export default AccessibilityPage
